import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckDirtyService {

  private _isDirty = new BehaviorSubject(false);
  currentFlag = this._isDirty.asObservable();

  constructor() { }

  changeDirtyFlag(flag: boolean) {
    this._isDirty.next(flag)
  }
}
