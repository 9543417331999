export const constants = {
  SAMPLE_INVENTORY_SHEET: "Inventory Template and Example Sheets (Restaurants and Bars).xlsx",
  HUBSPOT_EMAIL_CONFIRMATION_FORMID: "5ff7a312-1023-4ada-9d3c-99bff8ae06c9",
  HUBSPOT_EMAIL_VERIFICATION_FORMID: "492520b8-2ab0-4ab6-aad4-ce9c1ced9ddc",
  HUBSPOT_USER_REGISTER_FORMID: "2e4469f6-3b06-4207-bcc6-57babfc148b1",
  HUBSPOT_RESTAURANT_CREATE_FORMID: "3057a1b7-7162-4095-9580-3e2de7483b0f",
  FEATURED: "featured",
  SCOTT_ACCOUNT_EMAIL: "brandtester5@getkexy.com",
  KEXY_CALENDLY_URL: "https://calendly.com/kexy/meet-with-the-kexy-team",
  EMPLOYEE: "Employee",
  PRO: "Pro",
  FREE: "Free",
  MONTH: "month",
  YEAR: "year",
  MONTHLY: "Monthly",
  YEARLY: "Yearly",
  ANNUAL: "Annual",
  ENTERPRISE: "Enterprise",
  PAYMENT_STATUS: "subscription_payment_status",
  INVENTORY: "inventory",
  ORDERS: "orders",
  ORDER: "order",
  RESTAURANT: "restaurant",
  WEBPORTAL: "webportal",
  SUPPLIER: "supplier",
  BRAND: "brand",
  DISTRIBUTOR: "distributor",
  ADMIN: "admin",
  IMPRESSION: "impression",
  IMPRESSION_DAY: "impression_day",
  SUBSCRIPTION_MONTH: "subscription_month",
  SUBSCRIPTION_YEAR: "subscription_year",
  SUBSCRIPTION_LIFETIME: "subscription_lifetime",
  SUBSCRIPTION: "subscription",
  SUBSCRIPTION_TYPE: "subscription_type",
  ACCOUNT_TYPE: "account_type",
  INVITED_BY: "invited_by",
  RECCURING_TYPE: "recurring_type",
  GLEAP_KEY: "rEVzmGo71SZyqgf0tYkYa6lOf3YjnzKy",
  ACTIVE: "active",
  INACTIVE: "inactive",
  PUBLISHED: "published",
  SUCCESS: "success",
  DELETED: "deleted",
  PAUSE: "pause",
  COMPLETE: "complete",
  FINALIZED: "finalized",
  CREATED: "created",
  PENDING: "pending",
  CONFIRMED: "confirmed",
  RECEIVED: "received",
  ASENDING: "ASC",
  DESENDING: "DESC",
  STRIPE_ID: "advertiserStripeID",
  POPULAR_DISTRIBUTOR: "POPULAR_DISTRIBUTOR",
  TEST_POPULAR_DISTRIBUTOR: "TEST_POPULAR_DISTRIBUTOR",
  FREE_DISTRIBUTOR: "FREE_DISTRIBUTOR",
  FREE_ACCOUNT_ESTABLISHMENT_LIMIT: 15,
  DESKTOP_ALLOWED_WIDTH: 992,
  POPULAR_SUPPLIER: "POPULAR_SUPPLIER",
  BRAND_TEST: "BRAND_TEST",
  FREE_SUPPLIER: "FREE_SUPPLIER",
  BRAND_NOVICE: "BRAND_NOVICE",
  BRAND_AMATEUR: "BRAND_AMATEUR",
  BRAND_PRO: "BRAND_PRO",
  BRAND_STARTER: "BRAND_STARTER",
  PRO_RESTAURANT: "PRO_RESTAURANT",
  IMAGE: "image",
  VIDEO: "video",
  CAMPAIGN: "campaign",
  REFERRED_AFFILIATE_CODE: "referredAffiliateCode",
  AFFILIATE_CODE: "affiliateCode",
  SUPPLIER_ID: "supplierId",
  ADDITIONAL_CREDIT_PURCHASE: "ADDITIONAL_CREDIT_PURCHASE",
  CURRENT_ADDITIONAL_CREDIT: "CURRENT_ADDITIONAL_CREDIT",
  ONETIME: "onetime",
  BRAND_CAMPAIGN_PAGE_LIMIT: "brandPromotionsPageLimit",
  BRAND_DRIP_CAMPAIGN_PAGE_LIMIT: "brandDripCampaignPageLimit",
  DISTRIBUTOR_PROMOTION_PAGE_LIMIT: "distributorPromotionsPageLimit",
  UNSAVED_DEAL: "unsavedDeal",
  LIFETIME_SUBSCRIPTION_OFFER_MODAL_SHOW: "LifetimeSubscriptionOfferModalShow",
  NO_AUTH_DEAL_SIGNUP_DEALID: "noAuthDealUserSignupDealId",
  REG_RESTAURANT_ID: "reg_restaurant_id",
  COUPON_CODE: "couponCode",
  ZIP_POSTAL_CODE: "Zip/postal code",
  ZIPCODE: "zipcode",
  CITY: "City",
  // CITY: "city",
  STATE: "state",
  STATE_PROVINCE: "State/Province",
  SETTINGS: "Settings",
  SUPPORT: "Support",
  DASHBOARD: "Dashboard",
  YOUR_CURRENT_SUBSCRIPTION: "Your Current Subscription",
  DOWNGRADE: "Downgrade",
  UPGRADE: "Upgrade",
  MORE_INFO_SARAH: "More info from Sarah",
  ENTER: "Enter",
  DEALS: "deals",
  DEALS_WITH_REPORTS: "deals_with_reports",
  DEFAULT_TRIAL_DAY: 30,
  DEALS_WITH_REPORTS_NO_SUBSCRIPTION: "deals_with_reports_no_subscription",
  TAB: "Tab",
  RESTAURANT_FEATURES: "restaurant_feature",
  FOH: "FOH",
  BRAND_PRESALE_MONTHLY: "BRAND_PRESALE_MONTHLY",
  BRAND_299_MONTH_PER_USER: "BRAND_299_MONTH_PER_USER",
  BRAND_PRESALE_LIFETIME: "BRAND_PRESALE_LIFETIME",
  PRESALE: "presale",
  TITLES: {
    SUB_TITLE: "KEXY Restaurant Webportal",
    SEPARATOR: " - ",
    BUSINESS_OPERATIONS: "Business Operations",
    MENU_PERFORMANCE: "Menu Performance",
    RESTAURANT_FEATURES: "Features - KEXY Restaurant Portal",
  },
  KEXY: "kexy",
  TRIALING: "trialing",
  DEAL_LIST_PRICE: "Deal",
  PRODUCT_OFFERING_NO_PRICE_LISTED: "Featured Product",
  INFORMATIVE_BRAND_MARKETING: "Informative",
  DEAL_DISTRIBUTOR: "DISTRIBUTOR",
  DEAL_REPS: "REPS",
  LIST_PRODUCT_ON_PLATFORM: "Featured Service",
  DEAL_WITH_PRICE: "deal",
  FEATURED_PRODUCT: "featured_product",
  INFORMATIVE: "informative",
  LISTING_OF_PRODUCT: "product_listing",
  ANNOUNCEMENT_KEY: "announcement",
  ANNOUNCEMENT_VALUE: "Announcement",
  FAV_CAMPAIGN: "My Favorite Campaigns",
  LIST_INVOICE: "List of Invoices",
  DESKTOP: "desktop",
  MOBILE: "mobile",
  DRAFT_DEAL: "draft_deal",
  YOUTUBE_URL: "youtube.com",
  YOUTU_BE_URL: "youtu.be",
  DUPLICATE: "duplicate",
  PROSPECTING: "Prospecting",
  LIFETIME: "Lifetime",
  NOVICE: "Novice",
  AMATEUR: "Amateur",
  STARTER: "Starter",
  VERIFIED: "verified",
  AMATEUR_BRAND_MONTH_PRICE: "599",
  AMATEUR_BRAND_YEAR_PRICE: "399",
  PRO_BRAND_MONTH_PRICE: "999",
  PRO_BRAND_YEAR_PRICE: "719",
  US: "US",
  LAST_7_DAYS: "Last 7 days",
  LAST_30_DAYS: "Last 30 days",
  LAST_365_DAYS: "Last 365 days",
  NONE: "none",
  SUBMIT: "Submit",
  CAMPAIGN_CREATED_SUCCESSFULLY: "campaignCreatedSuccessFully",
  CAMPAIGN_UPDATED_SUCCESSFULLY: "campaignUpdatedSuccessFully",
  EDIT: "edit",
  ARCHIVE: "archive",
  EMAIL_EXIST: "EMAIL_EXIST",
  BETA_REGISTER_EMAIL: "betaRegisterEmail",
  BETA_REGISTER_PASSWORD: "betaRegisterPassword",
  TITLE: "title",
  PRICE: "price",
  SCOTT_BRAND_ACCOUNT_ID: 95,
  PROSPECTING_CALENDLY_LINKS: "prospectingCalendlyLinks",
  PROSPECTING_WEBSITE: "prospectingWebsite",
  SALES_LEAD_SEARCH_PAYLOAD: "sales-lead-search-payload",
  SALES_LEAD_SEARCH_JOB_TITLES_PAYLOAD: "sales-lead-search-job-titles-payload",
  SALES_LEAD_EMAIL_TONE: "sales-lead-email-tone",
  SALES_LEAD_PRODUCT: "sales-lead-product",
  SALES_LEAD_PRODUCT_CATEGORY: "sales-lead-product-category",
  SALES_LEAD_PRODUCT_DESCRIPTION: "sales-lead-product-description",
  SALES_LEAD_PRODUCT_WEBSITE: "sales-lead-product-website",
  SALES_LEAD_PRODUCT_CALENDLY: "sales-lead-product-calendly",
  SALES_LEAD_SELECT_AREA_SEARCH_TYPE: "sales-lead-select-area-search-type",
  SALES_LEAD_ZIP_CODE_LIST: "sales-lead-zip-code-list",
  SALES_LEAD_CITY_LIST: "sales-lead-city-list",
  SALES_LEAD_STATE_LIST: "sales-lead-state-list",
  SALES_LEAD_NUMBER_OF_ESTABLISHMENT: "sales-lead-number-of-establishment",
  SALES_LEAD_ORGANIZATION_INDUSTRY_TAG_IDS: "sales-lead-organization-industry-tag-ids",
  SALES_LEAD_PERSON_SENIORITIES: "sales-lead-person-seniorities",
  SALES_LEAD_SELECTED_TARGETTED_PEOPLE: "sales-lead-selected-targetted-people",
  USERTOKEN: "userToken",
  INDUSTRIES: [
    { key: "5567e0e0736964198de70700", value: "Restaurants", isSelected: false },
    { key: "5567ce9d7369643bc19c0000", value: "Hospitality", isSelected: false },
  ],
  CAMPAIGN_DETAILS: "campaign_details",
  CAMPAIGN_CONTACT: "campaign_contacts",
  CAMPAIGN_PREVIEW: "campaign_preview",
  CAMPAIGN_SUBMITTED: "campaign_submitted",
  OFF_PREMISE: "Off Premise",
  ON_PREMISE_FOH: "On Premise (FOH)",
  ON_PREMISE_BOH: "On Premise (BOH)",
  DTC: "DTC (Direct-to-Consumer)",
  QUEUED: "queued",
  EMAIL_TONES: [
    { key: "Friendly", value: "Friendly" },
    { key: "Normal_human", value: "Normal Human" },
    { key: "Casual", value: "Casual" },
    { key: "Luxury", value: "Luxury" },
    { key: "Professional", value: "Professional" },
    { key: "Relaxed", value: "Relaxed" },
    { key: "Bold", value: "Bold" },
    { key: "Witty", value: "Witty" },
    { key: "Persuasive", value: "Persuasive" },
    { key: "Empathetic", value: "Empathetic" },
    { key: "Emoji", value: "Emoji" },
  ],
  EMAIL_LENGTH_KEYS: [
    { key: "short", value: "Short" },
    { key: "medium", value: "Medium" },
    { key: "long", value: "Long" },
  ],
  DRIP_CAMPAIGN: "Drip Campaigns",
  PROMOTIONS: "Promotions",
  SALES_LEAD_COUNTRY: "sales-lead-country",
  CAMPAIGN_CONTENT: "campaign_content",
  GENERATE_EMAILS: "generate_emails",
  TEST_EMAIL_URL: "test_email_url",
  URL_PURCHASE_LINK: "url_purchase_link",
  USA_STATES_COUNT: 51,
  CANADA_STATES_COUNT: 10,
  AMATEUR_SUBSCRIPTION_PRICE_MONTH: "599",
  STARTER_SUBSCRIPTION_PRICE_MONTH: "299",
  STARTER_SUBSCRIPTION_PRICE_YEAR: "239",
  PRO_SUBSCRIPTION_PRICE_MONTH: "999",
  AMATEUR_SUBSCRIPTION_PRICE_YEAR: "479",
  PRO_SUBSCRIPTION_PRICE_YEAR: "799",
  MONTHLY_CREDIT_INFO: {
    img: "/assets/images/monthly-credit-img.png",
    title: "Monthly credit usage",
    progressColor: "blue",
    descText: "Credits are charged when you export a contact outside of KEXY or we send an email to a new prospect.  1 credit is equal to 1 email validation or export.",
    creditTextColor: "#12A5F4",
    total_credits: 0,
    remaining_credits: 0,
    buyMoreBtnShow: false,
  },
  ADDITIONAL_CREDIT_INFO: {
    img: "/assets/images/additional-credit-img.png",
    title: "Additional credit usage",
    progressColor: "orange",
    descText: "Additional credits can be purchased for a nominal cost.  Our Fair Use Policy limits plan to 25,000 credits per cycle to prevent abuse.",
    creditTextColor: "#F46112",
    total_credits: 0,
    remaining_credits: 0,
    buyMoreBtnShow: true,
  },
  SUBSCRIPTION_TYPES_DATA: [

  ],
  LIFETIME_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/double-star-blue-icon.svg",
    title: "Lifetime",
    subscriptionType: "BRAND_PRESALE_LIFETIME",
    lifeTime: true,
    active: false,
    price: "999",
    listYourProducts: "Unlimited",
    emailCredits: "1250",
    aiGeneratedSalesEmail: true,
    campaignPerformanceMatrics: true,
    featuredDealsAndProductCampaigns: false,
    informativeMarketingCampaigns: false,
    preferredPlacementOfProducts: false,
    priorityMatching: false,
    accountSetupAndTraining: false,
    showChangePlanOrCurrentPlanBtn: true
  },
  NOVICE_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/double-star-blue-icon.svg",
    title: "Novice",
    subscriptionType: "BRAND_NOVICE",
    active: false,
    price: "0",
    listYourProducts: "Unlimited",
    emailCredits: "25",
    aiGeneratedSalesEmail: true,
    campaignPerformanceMatrics: true,
    featuredDealsAndProductCampaigns: false,
    informativeMarketingCampaigns: false,
    preferredPlacementOfProducts: false,
    priorityMatching: false,
    accountSetupAndTraining: false,
    showChangePlanOrCurrentPlanBtn: true
  },
  AMATEUR_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/blue-amateur-subscription-icon.svg",
    title: "Amateur",
    subscriptionType: "BRAND_AMATEUR",
    active: false,
    price: "599",
    listYourProducts: "Unlimited",
    emailCredits: "500",
    aiGeneratedSalesEmail: true,
    campaignPerformanceMatrics: true,
    featuredDealsAndProductCampaigns: true,
    informativeMarketingCampaigns: true,
    preferredPlacementOfProducts: true,
    priorityMatching: true,
    accountSetupAndTraining: true,
    showChangePlanOrCurrentPlanBtn: true
  },
  PRO_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/blue-pro-subscription-icon.svg",
    title: "Pro",
    subscriptionType: "BRAND_PRO",
    active: false,
    price: "999",
    listYourProducts: "Unlimited",
    emailCredits: "1,250",
    aiGeneratedSalesEmail: true,
    campaignPerformanceMatrics: true,
    featuredDealsAndProductCampaigns: true,
    informativeMarketingCampaigns: true,
    preferredPlacementOfProducts: true,
    priorityMatching: true,
    accountSetupAndTraining: true,
    showChangePlanOrCurrentPlanBtn: true
  },
  ENTERPRISE_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/blue-enterprise-subscription-icon.svg",
    title: "Enterprise",
    price: "",
    active: false,
    listYourProducts: "Unlimited",
    emailCredits: "-",
    aiGeneratedSalesEmail: true,
    campaignPerformanceMatrics: true,
    featuredDealsAndProductCampaigns: true,
    informativeMarketingCampaigns: true,
    preferredPlacementOfProducts: true,
    priorityMatching: true,
    accountSetupAndTraining: true,
    showChangePlanOrCurrentPlanBtn: false
  },
  STARTER_SUBSCRIPTION_DATA: {
    icon: "/assets/icon/blue-enterprise-subscription-icon.svg",
    title: "Starter",
    price: "299",
    active: false,
    subscriptionType: "BRAND_STARTER",
    listYourProducts: "Unlimited",
    emailCredits: "250",
    aiGeneratedSalesEmail: true,
    campaignPerformanceMatrics: true,
    featuredDealsAndProductCampaigns: true,
    informativeMarketingCampaigns: true,
    preferredPlacementOfProducts: true,
    priorityMatching: false,
    accountSetupAndTraining: false,
    showChangePlanOrCurrentPlanBtn: true
  },
  INDUSTRY: 'industry',
  COMPANIES: "Companies",
  CONSUMERS: "Consumers",
  BROAD_CAMPAIGN: "Broad Campaign",
  FOCUSED_CAMPAIGN: "Focused Campaign",
  IMPORT_CAMPAIGN: "Import Campaign",
  WHO_COVER: "Objective: [i.e. request feedback, tell a story, etc.]\n\n" +
    "Email content: [What's the email about? Enter your email's main points here\n" +
    "- point 1\n" +
    "- point 2]\n\n" +
    "Call to action: [i.e. - book a meeting, purchase now]",
  YES: "yes",
  NO: "no",
  KEXY_SIGNUP_INDUSTRY_OPTIONS: [
    // {key: "restaurant", value: "Restaurant/Bar", type: "restaurant"},
    // {key: "brand", value: "Brand/Supplier", type: "brand"},
    {key: "accounting", value: "Accounting", type: "brand"},
    {key: "advertising_services", value: "Advertising Services", type: "brand"},
    {key: "Artists_and_writers", value: "Artists & Writers", type: "brand"},
    {key: "banking", value: "Banking", type: "brand"},
    {key: "blockchain_services", value: "Blockchain Services", type: "brand"},
    {key: "business_consulting_and_services", value: "Business Consulting & Services", type: "brand"},
    {key: "dentists", value: "Dentists", type: "brand"},
    {key: "education", value: "Education", type: "brand"},
    {key: "financial_services", value: "Financial Services", type: "brand"},
    {key: "food_and_beverage", value: "Food & Beverage", type: "brand"},
    {key: "fundraising", value: "Fundraising", type: "brand"},
    {key: "hospitality", value: "Hospitality", type: "brand"},
    {key: "insurance", value: "Insurance", type: "brand"},
    {key: "interior_design", value: "Interior Design", type: "brand"},
    {key: "investment_banking", value: "Investment Banking", type: "brand"},
    {key: "legal_services", value: "Legal Services", type: "brand"},
    {key: "medical_practices", value: "Medical Practices", type: "brand"},
    {key: "physicians", value: "Physicians", type: "brand"},
    {key: "real_estate", value: "Real Estate", type: "brand"},
    {key: "restaurant_and_bars", value: "Restaurant & Bars", type: "brand"},
    {key: "retail_apparel_and_fashion", value: "Retail Apparel & Fashion", type: "brand"},
    {key: "software_or_technology", value: "Software/Technology", type: "brand"},
    {key: "telecommunications", value: "Telecommunications", type: "brand"},
    {key: "venture_capital_and_private_equity", value: "Venture Capital & Private Equity", type: "brand"},
    {key: "other", value: "Other", type: "brand"},
  ],
  PURCHASE: "purchase",
  VISIT_WEBSITE: "visitWebsite",
  SEND_MESSAGE_CALL: "sendMessageCall",
  CUSTOM_BTN: "customBtn",
  PURCHASE_BTN_TEXT: "Purchase",
  VISIT_WEBSITE_BTN_TEXT: "Visit Website",
  SEND_MESSAGE_CALL_BTN_TEXT: "Send Message/Call",
  SEND_EMAIL: "Send Email",
  FOOD_AND_BEVERAGE: "Food & Beverage",
  UNITED_STATES: "United States",
  CANADA: "Canada",
  COUNTRIES: [
    { key: "unitedStates", value: "United States" },
    { key: "canada", value: "Canada" },
  ],
  MARKETING_STATUS_OPTIONS: [
    { key: "subscribed", value: "Subscribed" },
    { key: "unsubscribed", value: "Unsubscribed" },
  ],
  LABEL_COLOR_OPTIONS: [
    {labelBgColor: "#0078ff", labelTextColor: "#ffffff"},
    {labelBgColor: "#ddedff", labelTextColor: "#000000"},
    {labelBgColor: "#ff3541", labelTextColor: "#ffffff"},
    {labelBgColor: "#ffe4e6", labelTextColor: "#000000"},
    {labelBgColor: "#ff9900", labelTextColor: "#ffffff"},
    {labelBgColor: "#ffe6c0", labelTextColor: "#000000"},
    {labelBgColor: "#008377", labelTextColor: "#ffffff"},
    {labelBgColor: "#45b3a8", labelTextColor: "#ffffff"},
    {labelBgColor: "#3b3a4d", labelTextColor: "#ffffff"},
    {labelBgColor: "#757585", labelTextColor: "#ffffff"},
    {labelBgColor: "#35459e", labelTextColor: "#ffffff"},
    {labelBgColor: "#bbc5fb", labelTextColor: "#000000"},
    {labelBgColor: "#5938d9", labelTextColor: "#ffffff"},
    {labelBgColor: "#907ce3", labelTextColor: "#ffffff"},
  ],
  CONTACTS: "Contacts",
  COMPANY_DETAILS: "Company Details"
};
