import { Routes } from "@angular/router";

import { routeConstants } from "../helpers/routeConstants";
import { AuthGuard } from "../guard/auth.guard";

export const restaurantRoutes: Routes = [
  {
    path: routeConstants.RESTAURANT.CREATE_NEW_INVENTORY,
    loadChildren: () =>
      import("../pages/restaurant/create-new-inventory/create-new-inventory.module").then(
        (m) => m.CreateNewInventoryModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.LOCATION,
    loadChildren: () => import("../pages/restaurant/location/location.module").then((m) => m.LocationModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.INVITE_PEOPLE,
    loadChildren: () =>
      import("../pages/restaurant/invite-people/invite-people.module").then((m) => m.InvitePeopleModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.ORDERS,
    loadChildren: () => import("../pages/restaurant/orders/orders.module").then((m) => m.OrdersModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.PURCHASE_ORDES,
    loadChildren: () =>
      import("../pages/restaurant/purchase-orders/purchase-orders.module").then((m) => m.PurchaseOrdersModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.REPORTS,
    loadChildren: () => import("../pages/restaurant/reports/reports.module").then((m) => m.ReportsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.EDIT_PROFILE,
    loadChildren: () => import("../pages/restaurant/edit-profile/edit-profile.module").then((m) => m.EditProfileModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.EDIT_COMPANY,
    loadChildren: () => import("../pages/restaurant/edit-company/edit-company.module").then((m) => m.EditCompanyModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.VIEW_INVENTORY,
    loadChildren: () =>
      import("../pages/restaurant/new-inventory/new-inventory.module").then((m) => m.NewInventoryModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.INVENTORY,
    loadChildren: () => import("../pages/restaurant/inventory/inventory.module").then((m) => m.InventoryModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.SUBSCRIPTION,
    loadChildren: () =>
      import("../pages/restaurant/restaurant-subscription/restaurant-subscription.module").then(
        (m) => m.RestaurantSubscriptionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.INVITATION,
    loadChildren: () => import("../pages/restaurant/invitations/invitations.module").then((m) => m.InvitationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.INVITATION_LIST,
    loadChildren: () =>
      import("../pages/restaurant/invitation-list/invitation-list.module").then((m) => m.InvitationListModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.MARKETPLACE_TYPE,
    loadChildren: () =>
      import("../pages/restaurant/marketplace-type/marketplace-type.module").then((m) => m.MarketplaceTypeModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.CREATE,
    loadChildren: () =>
      import("../pages/restaurant/restaurant-create/restaurant-create.module").then((m) => m.RestaurantCreateModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.RESTAURANT_FEATURE_SELECT,
    loadChildren: () =>
      import("../pages/restaurant/restaurant-feature-selection/restaurant-feature-selection.module").then(
        (m) => m.RestaurantFeatureSelectionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.LIST_PROMOTIONS,
    loadChildren: () =>
      import("../pages/restaurant/list-of-deals/list-of-deals.module").then((m) => m.ListOfDealsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.FAV_DEALS,
    loadChildren: () =>
      import("../pages/restaurant/favorite-deals/favorite-deals.module").then((m) => m.FavoriteDealsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.DEAL_INVOICE,
    loadChildren: () =>
      import("../pages/restaurant/deal-invoices/deal-invoices.module").then((m) => m.DealInvoicesModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.DEAL_DETAILS,
    loadChildren: () => import("../pages/restaurant/deal-details/deal-details.module").then((m) => m.DealDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.IMPORT_DATA,
    loadChildren: () => import("../pages/restaurant/import-data/import-data.module").then((m) => m.ImportDataModule),
    canActivate: [AuthGuard],
  },

  {
    path: routeConstants.RESTAURANT.DEAL_CHECKOUT,
    loadChildren: () =>
      import("../pages/restaurant/deals-checkout/deals-checkout.module").then((m) => m.DealsCheckoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.MOBILE_DESIGN,
    loadChildren: () =>
      import("../pages/restaurant/mobile-tablet-responsive/mobile-tablet-responsive.module").then(
        (m) => m.MobileTabletResponsiveModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.WELCOME,
    loadChildren: () =>
      import("../pages/restaurant/welcome-restaurant/welcome-restaurant.module").then((m) => m.WelcomeRestaurantModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.ORGANIZATION_LIST,
    loadChildren: () =>
      import("../pages/restaurant/organization-list/organization-list.module").then((m) => m.OrganizationListModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.ORGANIZATION,
    loadChildren: () =>
      import("../pages/restaurant/organization/organization.module").then((m) => m.OrganizationModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.ADD_BUSINESS,
    loadChildren: () => import("../pages/restaurant/add-business/add-business.module").then((m) => m.AddBusinessModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.RESTAURANT_SUBSCRIPTION_SELECTION,
    loadChildren: () =>
      import("../pages/restaurant/restaurant-subscription-selection/restaurant-subscription-selection.module").then(
        (m) => m.RestaurantSubscriptionSelectionModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.UPLOAD_FILE,
    loadChildren: () => import("../pages/restaurant/upload-file/upload-file.module").then((m) => m.UploadFileModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.SELECT_KPI,
    loadChildren: () => import("../pages/restaurant/select-kpi/select-kpi.module").then((m) => m.SelectKpiModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.BUSINESS_OPERATIONS,
    loadChildren: () =>
      import("../pages/restaurant/business-operation/business-operation.module").then((m) => m.BusinessOperationModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.MENU_PERFORMANCE,
    loadChildren: () => import("../pages/restaurant/menu-items/menu-items.module").then((m) => m.MenuItemsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.ORDER_DETAILS,
    loadChildren: () =>
      import("../pages/restaurant/order-details/order-details.module").then((m) => m.OrderDetailsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.RESTAURANT.INVENTORY_DETAILS,
    loadChildren: () =>
      import("../pages/restaurant/inventory-details/inventory-details.module").then((m) => m.InventoryDetailsModule),
    canActivate: [AuthGuard],
  },
];
