import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FohbohService {
  private side = localStorage.getItem('side');
  private _isFoh = new BehaviorSubject(this.side && this.side.toLowerCase() === 'foh');
  currentMessage = this._isFoh.asObservable();

  constructor() {

  }

  changeFoh(isFoh: boolean) {
    this._isFoh.next(isFoh)
  }

}
