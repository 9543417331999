import { NgModule } from "@angular/core";
import { DatePipe } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpService } from "./services/http.service";
import { AuthService } from "./services/auth.service";
import { NodeSocketService } from "./services/node-socket.service";
import { GoogleAnalyticsService } from "./services/google-analytics.service";
import { CartService } from "./services/cart.service";
import { DistributorService } from "./services/distributor.service";
import { FohbohService } from "./services/fohboh.service";
import { CheckDirtyService } from "./services/check-dirty.service";
import { CampaignService } from "./services/campaign.service";
import { CategoryService } from "./services/category.service";

import { JwtInterceptor } from "./helpers/jwt.interceptor";
import { ErrorInterceptor } from "./helpers/error.interceptor";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
// import { ContextMenuModule } from 'ngx-contextmenu';

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { PreviewService } from "./services/preview.service";
import { ProspectingService } from "./services/prospecting.service";
import { DripCampaignService } from "./services/drip-campaign.service";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    // ContextMenuModule,
  ],
  // exports: [NgbModule, ContextMenuModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    HttpService,
    AuthService,
    CartService,
    ProspectingService,
    CampaignService,
    DripCampaignService,
    PreviewService,
    DistributorService,
    CategoryService,
    NodeSocketService,
    FohbohService,
    CheckDirtyService,
    GoogleAnalyticsService,
    DatePipe,
    NgbActiveModal,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
