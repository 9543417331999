import { Routes } from "@angular/router";

import { routeConstants } from "../helpers/routeConstants";
import { AuthGuard } from "../guard/auth.guard";
// import { DistributorCreateComponent } from '../pages/distributor/distributor-create/distributor-create.component';
// import { DistributorListComponent } from '../pages/distributor/distributor-list/distributor-list.component';
// import { AddDistributorComponent } from "../pages/distributor/add-distributor/add-distributor.component";
// import { WelcomeDistributorComponent } from '../pages/distributor/welcome-distributor/welcome-distributor.component';
// import { CreateDealComponent } from '../pages/distributor/create-deal/create-deal.component';
// import { InvoiceListComponent } from '../pages/distributor/invoice-list/invoice-list.component';
// import { SubscriptionComponent } from '../pages/distributor/subscription/subscription.component';
// import { MarketingToolsComponent } from '../pages/distributor/marketing-tools/marketing-tools.component';
// import { AnalyticsComponent } from '../pages/distributor/analytics/analytics.component';
// import { DistributorsComponent } from '../pages/distributor/distributors/distributors.component';
import { DistributorInvitePeopleComponent } from "../pages/distributor/distributor-invite-people/distributor-invite-people.component";
// import { EditDistributorComponent } from '../pages/distributor/edit-distributor/edit-distributor.component';
// import { DistributorDealsComponent } from '../pages/distributor/distributor-deals/distributor-deals.component';
// import { EditDistributorUserComponent } from '../pages/distributor/edit-distributor-user/edit-distributor-user.component';
// import { SubscriptionSelectionComponent } from '../pages/distributor/subscription-selection/subscription-selection.component';
// import { DistributorInvitationsComponent } from '../pages/distributor/distributor-invitations/distributor-invitations.component';
// import { DistributorInvitationListComponent } from '../pages/distributor/distributor-invitation-list/distributor-invitation-list.component';
import { constants } from "src/app/helpers/constants";

export const distributorRoutes: Routes = [
  {
    path: constants.DISTRIBUTOR,
    redirectTo: routeConstants.DISTRIBUTOR.CREATE,
    pathMatch: "full",
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.CREATE,
  //   component: DistributorCreateComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: routeConstants.DISTRIBUTOR.CREATE,
    loadChildren: () =>
      import("../pages/distributor/distributor-create/distributor-create.module").then(
        (m) => m.DistributorCreateModule
      ),
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.ORGANIZATION_LIST,
  //   component: DistributorListComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: routeConstants.DISTRIBUTOR.ORGANIZATION_LIST,
    loadChildren: () =>
      import("../pages/distributor/distributor-list/distributor-list.module").then((m) => m.DistributorListModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.INVITATION,
  //   component: DistributorInvitationsComponent,
  // },
  {
    path: routeConstants.DISTRIBUTOR.INVITATION,
    loadChildren: () =>
      import("../pages/distributor/distributor-invitations/distributor-invitations.module").then(
        (m) => m.DistributorInvitationsModule
      ),
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.INVITATION_LIST,
  //   component: DistributorInvitationListComponent,
  // },
  {
    path: routeConstants.DISTRIBUTOR.INVITATION_LIST,
    loadChildren: () =>
      import("../pages/distributor/distributor-invitation-list/distributor-invitation-list.module").then(
        (m) => m.DistributorInvitationListModule
      ),
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.CREATE_PROMOTION,
  //   component: CreateDealComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: routeConstants.DISTRIBUTOR.CREATE_PROMOTION,
  //   loadChildren: () => import("../pages/distributor/create-deal/create-deal.module").then((m) => m.CreateDealModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: routeConstants.DISTRIBUTOR.CREATE_PROMOTION,
  //   loadChildren: () =>
  //     import("../pages/distributor/create-promotion/create-promotion.module").then((m) => m.CreatePromotionModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: routeConstants.DISTRIBUTOR.SUBSCRIPTION_SELECTION,
  //   component: SubscriptionSelectionComponent,
  // },
  {
    path: routeConstants.DISTRIBUTOR.SUBSCRIPTION_SELECTION,
    loadChildren: () =>
      import("../pages/distributor/subscription-selection/subscription-selection.module").then(
        (m) => m.SubscriptionSelectionModule
      ),
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.WELCOME,
  //   component: WelcomeDistributorComponent,
  // },
  {
    path: routeConstants.DISTRIBUTOR.WELCOME,
    loadChildren: () =>
      import("../pages/distributor/welcome-distributor/welcome-distributor.module").then(
        (m) => m.WelcomeDistributorModule
      ),
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.EDIT_PROFILE,
  //   component: EditDistributorUserComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: routeConstants.DISTRIBUTOR.EDIT_PROFILE,
    loadChildren: () =>
      import("../pages/distributor/edit-distributor-user/edit-distributor-user.module").then(
        (m) => m.EditDistributorUserModule
      ),
    canActivate: [AuthGuard],
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.LIST_PROMOTIONS,
  //   component: DistributorDealsComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: routeConstants.DISTRIBUTOR.LIST_PROMOTIONS,
    loadChildren: () =>
      import("../pages/distributor/distributor-deals/distributor-deals.module").then((m) => m.DistributorDealsModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.LIST_INVOICE,
  //   component: InvoiceListComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: routeConstants.DISTRIBUTOR.LIST_INVOICE,
    loadChildren: () =>
      import("../pages/distributor/invoice-list/invoice-list.module").then((m) => m.InvoiceListModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.EDIT_COMPANY,
  //   component: EditDistributorComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: routeConstants.DISTRIBUTOR.EDIT_COMPANY,
    loadChildren: () =>
      import("../pages/distributor/edit-distributor/edit-distributor.module").then((m) => m.EditDistributorModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.SUBSCRIPTION,
  //   component: SubscriptionComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: routeConstants.DISTRIBUTOR.SUBSCRIPTION,
    loadChildren: () =>
      import("../pages/distributor/subscription/subscription.module").then((m) => m.SubscriptionModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.MARKETING_TOOLS,
  //   component: MarketingToolsComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: routeConstants.DISTRIBUTOR.MARKETING_TOOLS,
    loadChildren: () =>
      import("../pages/distributor/marketing-tools/marketing-tools.module").then((m) => m.MarketingToolsModule),
    canActivate: [AuthGuard],
  },
  {
    path: routeConstants.DISTRIBUTOR.INVITE_PEOPLE,
    component: DistributorInvitePeopleComponent,
    canActivate: [AuthGuard],
  },
  // { path: routeConstants.DISTRIBUTOR.ANALYTICS, component: AnalyticsComponent },
  {
    path: routeConstants.DISTRIBUTOR.ANALYTICS,
    loadChildren: () => import("../pages/distributor/analytics/analytics.module").then((m) => m.AnalyticsModule),
  },
  // {
  //   path: routeConstants.DISTRIBUTOR.ORGANIZATION,
  //   component: DistributorsComponent,
  // },
  {
    path: routeConstants.DISTRIBUTOR.ORGANIZATION,
    loadChildren: () =>
      import("../pages/distributor/distributors/distributors.module").then((m) => m.DistributorsModule),
  },
  // { path: routeConstants.DISTRIBUTOR.ADD_BUSINESS, component: AddDistributorComponent },
  {
    path: routeConstants.DISTRIBUTOR.ADD_BUSINESS,
    loadChildren: () =>
      import("../pages/distributor/add-distributor/add-distributor.module").then((m) => m.AddDistributorModule),
  },
];
