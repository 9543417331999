import { Component, OnInit } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { Router } from "@angular/router";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpService } from "../../services/http.service";
import { Location } from "@angular/common";
import { routeConstants } from "../../helpers/routeConstants";
declare let mixpanel: any;

@Component({
  selector: "app-not-found",
  templateUrl: "./not-found.component.html",
  styleUrls: ["./not-found.component.scss"],
})
export class NotFoundComponent implements OnInit {
  isLoggedIn: boolean = false;
  userData;

  constructor(
    private _authService: AuthService,
    private router: Router,
    private modal: NgbModal,
    private httpService: HttpService,
    private location: Location
  ) {}

  ngOnInit() {
    document.title = "Welcome - KEXY Client Portal";
    mixpanel.track("View Welcome Client Page");

    let userTokenValue = this._authService.userTokenValue;
    if (userTokenValue) {
      this.isLoggedIn = true;
      this.userData = userTokenValue;
      console.log(userTokenValue);
    }
  }

  get username(): string {
    return this.userData ? this.userData.first_name + " " + this.userData.last_name : "";
  }

  editProfileTapped() {
    this.router.navigate([routeConstants.RESTAURANT.EDIT_PROFILE]);
  }

  editCompanyTapped() {
    this.router.navigate([routeConstants.RESTAURANT.EDIT_COMPANY]);
  }

  handleLogout() {
    this._authService.logout();
    this.modal.dismissAll();
    window.location.href = "/" + routeConstants.LOGIN;
  }

  subscriptionsTapped() {
    this.router.navigate([routeConstants.RESTAURANT.SUBSCRIPTION]);
  }

  goBack() {
    this.location.back();
  }
}
