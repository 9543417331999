import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class DistributorService {
  private _distributorName = new BehaviorSubject("");
  distributorName = this._distributorName.asObservable();

  private _distributorId = new BehaviorSubject("");
  distributorId = this._distributorId.asObservable();

  private _distributorList = new BehaviorSubject([]);
  distributorList = this._distributorList.asObservable();

  private _repEditData = new BehaviorSubject({});
  repEditData = this._repEditData.asObservable();

  constructor(private httpService: HttpService) {}

  changeRepEditData = (data) => {
    this._repEditData.next(data);
  }

  changeDistributorName(name) {
    this._distributorName.next(name);
  }

  changeDistributorId(id) {
    this._distributorId.next(id);
  }

  getAllDistributor = async (supplierId) => {
    let data = {
      supplier_id: supplierId,
    };
    let res = await this.httpService.post("supplier/getDistributors", data).toPromise();
    this._distributorList.next(res.data.distributors);
  };
}
