import { Component, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { constants } from './helpers/constants';
import Gleap from 'gleap';

// Please make sure to call this method only once!
Gleap.initialize(constants.GLEAP_KEY);

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title = 'kexy-webportal';

  constructor(public router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'AW-641460879', {
          page_path: event.urlAfterRedirects,
        });
      }
    });



  }
}
