import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, FormBuilder, Validators } from "@angular/forms";
import { FlashMessagesService } from "angular2-flash-messages";
import Swal from "sweetalert2";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { HttpService } from "src/app/services/http.service";
declare let mixpanel: any;

@Component({
  selector: "app-invite-people",
  templateUrl: "./distributor-invite-people.component.html",
  styleUrls: ["./distributor-invite-people.component.scss"],
})
export class DistributorInvitePeopleComponent implements OnInit {
  primaryForm: FormGroup;
  submitted: boolean = false;
  error;
  isWaitingFlag: boolean = false;
  isLoadingListFlag: boolean = false;
  isSubmitFlag: boolean = false;
  userData;
  invitedUsersList = [];
  formRowCount = [1];

  constructor(
    private _authService: AuthService,
    private httpService: HttpService,
    private _flashMessagesService: FlashMessagesService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.primaryForm = this.fb.group({
      peoplesList: this.fb.array([]),
    });
  }

  async ngOnInit() {
    this._authService.loggedUserRedirectToProperDashboard();
    document.title = "Invite People - KEXY Distributor Webportal";

    mixpanel.track("View Invite People Page");
    console.log(123);

    //add default form field - atleast 1 row
    this.peoplesList().push(this.newPeopleRow());

    this.userData = this._authService.userTokenValue;
    //console.log("userData", this.userData);

    let data = {
      restaurant_id: "",
      distributor_id: this.userData.distributor_id,
      supplier_id: "",
    };

    this.isLoadingListFlag = true;
    this.httpService.post("user/listInvitations", data).subscribe((response) => {
      if (response) {
        this.isLoadingListFlag = false;
        this.invitedUsersList = response.data.employee;
      }
    });
  }

  peoplesList(): FormArray {
    return this.primaryForm.get("peoplesList") as FormArray;
  }

  newPeopleRow(): FormGroup {
    return this.fb.group({
      userEmail: new FormControl("", Validators.compose([Validators.required, Validators.email])),
      userRole: new FormControl("", Validators.compose([Validators.required])),
    });
  }

  addPeopleRow() {
    this.peoplesList().push(this.newPeopleRow());
  }

  removePeople(i: number) {
    this.peoplesList().removeAt(i);
  }

  async primaryFormSubmitted(): Promise<any> {
    this.submitted = true;
    this.isSubmitFlag = true;

    mixpanel.track("Send Employee Invitation(s) Button Clicked");

    if (!this.primaryForm.valid) {
      this.isSubmitFlag = false;
      return;
    }

    let data = {
      distributor_id: this.userData.distributor_id,
      invited_employee_list: [],
    };

    let formData = this.primaryForm.getRawValue();
    let formSubmittedData = formData.peoplesList.map((peopleData) => {
      return {
        email: peopleData.userEmail,
        role: peopleData.userRole,
      };
    });

    data.invited_employee_list = formSubmittedData;

    let response = await this.httpService.post("distributor/inviteEmployees", data).toPromise();
    if (response.success) {
      this.isSubmitFlag = false;
      formData.peoplesList.map((peopleData) => {
        let newList = {
          email: peopleData.userEmail,
          role: peopleData.userRole,
          action: "sent",
        };
        this.invitedUsersList.push(newList);
      });
      this.primaryForm.reset();
      Swal.fire("Done!", "Invitation has been sent.", "success");
    } else {
      this.isSubmitFlag = false;
      let message = "There was an error!";
      if (response.error && response.error.code && response.error.message) {
        message = response.error.message;
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
      });
    }
  }

  async resendInvitation(user, index) {
    //console.log( user, index );
    this.isWaitingFlag = true;
    let resendData = {
      resend: true,
      distributor_id: this.userData.distributor_id,
      invited_employee_list: [
        {
          email: user.email,
          role: user.role,
        },
      ],
    };

    let response = await this.httpService.post("distributor/inviteEmployees", resendData).toPromise();
    if (response.success) {
      this.isWaitingFlag = false;
      Swal.fire("Done!", "Invitation has been sent again.", "success");
    } else {
      this.isWaitingFlag = false;
      let message = "There was an error!";
      if (response.error && response.error.code && response.error.message) {
        message = response.error.message;
      }
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: message,
      });
    }
  }

  /**
   * Deletes a user from List
   * @param index
   */
  async deleteInvitedUser(index, user: any) {
    let isConfirm = await Swal.fire({
      title: "Do you want to delete this user?",
      text: "Important:  You cannot undo this action",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    });

    if (isConfirm.dismiss) {
      return;
    }
    this.isWaitingFlag = true;

    await this.httpService
      .post("distributor/deleteUser", {
        employee_invitation_id: user.id,
      })
      .toPromise();

    this.invitedUsersList.splice(index, 1);
    this.isWaitingFlag = false;
  }
}
