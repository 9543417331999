import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { routeConstants } from "./helpers/routeConstants";
import { restaurantRoutes } from "./routes/restaurantRoutes";
import { brandRoutes } from "./routes/brandRoutes";
import { distributorRoutes } from "./routes/distributorRoutes";

const routes: Routes = [
  { path: "", redirectTo: "/" + routeConstants.LOGIN, pathMatch: "full" },
  {
    path: routeConstants.LOGIN,
    loadChildren: () => import("./pages/login/login.module").then((m) => m.LoginModule),
  },
  {
    path: routeConstants.JOIN,
    loadChildren: () => import("./pages/join-marketplace/join-marketplace.module").then((m) => m.JoinMarketplaceModule),
  },
  {
    path: routeConstants.EMAIL_CONFIRMATION,
    loadChildren: () =>
      import("./pages/email-confirmation/email-confirmation.module").then((m) => m.EmailConfirmationModule),
  },
  {
    path: routeConstants.EMAIL_VERIFICATION,
    loadChildren: () =>
      import("./pages/email-verification/email-verification.module").then((m) => m.EmailVerificationModule),
  },
  {
    path: routeConstants.FORGET_PASSWORD,
    loadChildren: () => import("./pages/forgot-password/forgot-password.module").then((m) => m.ForgotPasswordModule),
  },
  {
    path: routeConstants.RESET_PASSWORD,
    loadChildren: () => import("./pages/reset-password/reset-password.module").then((m) => m.ResetPasswordModule),
  },
  {
    path: routeConstants.REGISTER,
    loadChildren: () => import("./pages/register/register.module").then((m) => m.RegisterModule),
  },
  {
    path: routeConstants.MESSAGE_REPLY,
    loadChildren: () =>
      import("./pages/restaurant/message-reply/message-reply.module").then((m) => m.MessageReplyModule),
  },
  {
    path: routeConstants.SSO,
    loadChildren: () => import("./pages/restaurant/sso/sso.module").then((m) => m.SsoModule),
  },
  {
    path: routeConstants.AFFILIATE.SIGNUP,
    loadChildren: () =>
      import("./pages/affiliate/affiliate-signup/affiliate-signup.module").then((m) => m.AffiliateSignupModule),
  },
  {
    path: routeConstants.AFFILIATE.PROGRESS,
    loadChildren: () =>
      import("./pages/affiliate/affiliate-steps/affiliate-steps.module").then((m) => m.AffiliateStepsModule),
  },
  {
    path: routeConstants.AFFILIATE.THANK_YOU,
    loadChildren: () =>
      import("./pages/affiliate/affiliate-thank-you/affiliate-thank-you.module").then((m) => m.AffiliateThankYouModule),
  },

  {
    path: routeConstants.PRESALE.SIGNUP,
    loadChildren: () =>
      import("./pages/presale/supplier-presale-signup/supplier-presale-signup.module").then(
        (m) => m.SupplierPresaleSignupModule
      ),
  },

  {
    path: routeConstants.PRESALE.PROGRESS,
    loadChildren: () =>
      import("./pages/presale/supplier-presale-progress/supplier-presale-progress.module").then(
        (m) => m.SupplierPresaleProgressModule
      ),
  },

  {
    path: routeConstants.PRESALE.THANK_YOU,
    loadChildren: () =>
      import("./pages/presale/presale-thank-you/presale-thank-you.module").then((m) => m.PresaleThankYouModule),
  },

  {
    path: routeConstants.PRESALE.LOGIN,
    loadChildren: () =>
      import("./pages/presale/presale-login/presale-login.module").then((m) => m.PresaleLoginModule),
  },

  {
    path: routeConstants.RESTAURANT.PROMOTION_NO_AUTH,
    loadChildren: () =>
      import("./pages/public/public-promotions/public-promotions.module").then(
        (m) => m.PublicPromotionsModule
      ),
  },

  {
    path: routeConstants.PUBLIC_PROMOTION_ATTRIBUTES,
    loadChildren: () =>
      import("./pages/public/public-promotion-attributes/public-promotion-attributes.module").then((m) => m.PublicPromotionAttributesModule),
  },

  // All restaurant routes
  ...restaurantRoutes,

  // All brand routes
  ...brandRoutes,

  // All distributor routes
  ...distributorRoutes,

  // 404 Not Found Route
  { path: "**", pathMatch: "full", component: NotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
