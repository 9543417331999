<div class="not-found-page">
  <div class="row" *ngIf="isLoggedIn">
    <div class="col-12">
      <div class="header">
        <div ngbDropdown class="d-inline-block">
          <button
            class="btn btn-link kx-user-name"
            id="dropdownBasic1"
            ngbDropdownToggle
          >
            <i class="fa fa-user-o"></i> {{ username }}
          </button>
          <div
            ngbDropdownMenu
            aria-labelledby="dropdownBasic1"
            class="kx-dropdown-menu"
          >
            <button ngbDropdownItem (click)="editProfileTapped()">
              <i class="fa fa-user" aria-hidden="true"></i> Edit Profile
            </button>
            <!-- *ngIf="isAdmin" -->
            <button ngbDropdownItem (click)="editCompanyTapped()">
              <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit
              Company
            </button>
            <button ngbDropdownItem (click)="subscriptionsTapped()">
              <i class="fa fa-credit-card" aria-hidden="true"></i> Subscriptions
            </button>
            <button ngbDropdownItem (click)="handleLogout()">
              <i class="fa fa-sign-out" aria-hidden="true"></i> Logout
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row justify-content-center" style="flex: 1; align-items: center">
    <div class="col-7 text-center">
      <div class="content-wrap">
        <div class="not-found-img-wrap">
          <img src="/assets/images/not-found.png" class="marketing-img" />
        </div>
        <div class="mt-5">
          <h1 class="text-center font-weight-bold">Page Not Found</h1>
          <p class="text-center font-weight-bold">
            We're sorry, we couldn't find the page you requested.
          </p>
          <button class="back-btn" (click)="goBack()">Back</button>
        </div>
      </div>
    </div>
  </div>
</div>
