import { Injectable } from "@angular/core";

import * as io from "socket.io-client";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable()
export class NodeSocketService {
  private user_id: any = null;
  private isSubscribed = false;
  private endPointList: { eventName: string; propertyName: string; observable }[];
  private requestQueue: { eventName: string; data: object }[];
  userListObserver: any;
  __notify: () => void;
  messageLog: string;
  private socket: any;

  constructor() {
    this._message("(NodeSocketProvider)> Constructor");
    this.__conn();
  }

  __conn() {
    this._message("(NodeSocketProvider)> Provider Created.");

    let url = environment.socketUri;
    this.socket = io(url, {
      forceNew: true,
      reconnectionDelay: 500,
      reconnectionDelayMax: 2000,
      transports: ["polling"],
    });

    this.messageLog = "";
    this.endPointList = [
      { eventName: "conversation-list-updated", propertyName: "...", observable: null },
      { eventName: "new-conversation-created", propertyName: "...", observable: null },
      { eventName: "online-list", propertyName: "...", observable: null },
      { eventName: "message-list", propertyName: "...", observable: null },
      { eventName: "new-message-queued", propertyName: "...", observable: null },
    ];

    this.requestQueue = [];

    this.socket.on("connect", () => this.onConnect());
    this.socket.on("reconnect", () => this.onReconnect());
    this.socket.on("disconnect", () => this.onDisconnect());

    this.socket.on("error", (err) => {
      this._message("(NodeSocketProvider)> Socket Error" + JSON.stringify(err));
    });

    this.socket.on("connect_error", (err) => {
      this._message("(NodeSocketProvider)> Socket connect_error" + JSON.stringify(err));
    });

    this.initEndpoints();
    this.initMaintainOnlineList();

    this.socket.connect();
  }

  public _setUpReconnector() {
    this._message("(NodeSocketProvider)> Socket Manually Trying to Disconnect.");

    this.socket.disconnect();
    this.socket.connect();
    window.setTimeout(() => this._setUpReconnector(), 30000);
  }

  public setUserId(user_id) {
    if (this.user_id === user_id && this.isSubscribed) return;
    this._message(`(NodeSocketProvider)> user_id SET TO ${user_id}`);
    this.isSubscribed = false;
    this.user_id = user_id;
    this.subscribeToRemote();
  }

  public get isConnected() {
    return this.socket.connected;
  }

  private processRequestQueue() {
    if (!this.isSubscribed) return;
    this.requestQueue.forEach(({ eventName, data }) => {
      this._message(`(NodeSocketProvider)> Emitting ${eventName} - ${data}`);
      this.socket.emit(eventName, data);
    });
    this.requestQueue = [];
  }

  private subscribeToRemote() {
    if (!this.user_id) return;
    if (this.isSubscribed) return;
    this.socket.once("subscribed", () => {
      this._message("(NodeSocketProvider)> Subscription Acquired.");
      this.isSubscribed = true;
      this.processRequestQueue();
    });
    this._message("(NodeSocketProvider)> Subscription Requested.");
    this.socket.emit("subscribe", { user_id: this.user_id }, (res) => {
      this._message("(NodeSocketProvider)> Subscription Requested. RES" + JSON.stringify(res));
    });
  }

  private onConnect() {
    this._message("(NodeSocketProvider)> Socket Connected.");
    this.subscribeToRemote();
  }

  private _message(message) {}

  private onReconnect() {
    this._message("(NodeSocketProvider)> Socket Reconnected.");
  }

  private onDisconnect() {
    this._message("(NodeSocketProvider)> Socket Disconnected.");
    this.isSubscribed = false;
  }

  private initEndpoints() {
    this._message("(NodeSocketProvider)> Setting up Endpoints qwqw");

    this.endPointList.forEach((endPoint) => {
      endPoint.observable = new Observable((observer) => {
        this.socket.on(endPoint.eventName, (data) => {
          this._message(`(NodeSocketProvider)> Received: "${endPoint.eventName}"`);
          observer.next(data);
        });
      });
    });
  }

  public subscribeToUserOnlineStatus(cbfn) {
    this.userListObserver.subscribe(cbfn);
    this.__notify();
  }

  private initMaintainOnlineList() {
    this.userListObserver = new Observable((observer) => {
      let online_user_id_list = [];

      this.__notify = () => {
        observer.next(online_user_id_list);
      };

      this.socket.on("global-online", (user_id) => {
        let i = online_user_id_list.indexOf(user_id);
        if (i === -1) {
          online_user_id_list.push(user_id);
          this.__notify();
        }
      });

      this.socket.on("global-offline", (user_id) => {
        let i = online_user_id_list.indexOf(user_id);
        if (i > -1) {
          online_user_id_list.splice(i, 1);
          this.__notify();
        }
      });

      this.socket.on("online-list", (user_list) => {
        let new_online_user_id_list = user_list.map((i) => parseInt(String(i)));

        let array1 = new_online_user_id_list;
        let array2 = online_user_id_list;
        if (!(array1.length === array2.length && array1.every((value, index) => value === array2[index]))) {
          online_user_id_list = new_online_user_id_list;
          this.__notify();
        }
      });

      this.emit("request-push", { event: "online-list" });

    });
  }

  public event(eventName) {
    let endPoint = this.endPointList.find((e) => e.eventName === eventName);
    if (!endPoint) throw new Error("Invalid Endpoint Name Provided");
    return endPoint.observable;
  }

  public emit(eventName, data) {
    this.requestQueue.push({ eventName, data });
    this.processRequestQueue();
  }
}
