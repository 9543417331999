import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";

@Injectable()
export class CartService {
  private _cartItems = new BehaviorSubject([]);
  cartItems = this._cartItems.asObservable();

  constructor() {}

  addCartItems(deal) {
    let items = [...this._cartItems.getValue()];

    const cartDeal = items.find((c) => c.id === deal.id);
    if (cartDeal) {
      cartDeal.cartQuantity++;
      cartDeal.cartPrice = cartDeal.cartQuantity * deal.price;
    } else {
      deal.cartQuantity = 1;
      deal.cartPrice = deal.price;
      items.push(deal);
    }

    this._cartItems.next(items);
  }

  decrementSameCartItem(deal) {
    let items = [...this._cartItems.getValue()];
    let cartDeal = items.find((c) => c.id === deal.id);

    if (cartDeal && cartDeal.cartQuantity > 0) {
      cartDeal.cartQuantity--;
      cartDeal.cartPrice = cartDeal.cartQuantity * deal.price;
      this._cartItems.next(items);
    }
  }

  checkDealInCart = (dealId) => {
    let items = [...this._cartItems.getValue()];
    return items.find((c) => c.id === dealId);
  }

  removeCartItems(cartItem) {
    const cartItems = this._cartItems.getValue().filter((c) => c.id !== cartItem.id);
    this._cartItems.next(cartItems);
  }

  emptyCartItems() {
    this._cartItems.next([]);
  }
}
